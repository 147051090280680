import '../../loader.css'

const Loading = () => {
    return (
        <div className="loader">
            <div className="segment-holder">
                <div className="segment one"></div>
            </div>
            <div className="segment-holder">
                <div className="segment two"></div>
            </div>
            <div className="segment-holder">
                <div className="segment three"></div>
            </div>
            <div className="segment-holder">
                <div className="segment four"></div>
            </div>
        </div>
    );
};

export default Loading;
