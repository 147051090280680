const HomeAboutSection = ({styles, imgPath, imgAlt, heading, text, child}) => {


    let aos_effects = [
        'fade-right',
        'zoom-in-right',
        'fade-down',
    ]

    let aos_durations = [
        '500',
        '1000',
        '2000'
    ]
    
    
    return ( 
        <div
            data-aos={child===1?aos_effects[0]:(child===2?aos_effects[1]:aos_effects[2])}
            data-aos-duration={child===1?aos_durations[0]:(child===2?aos_durations[1]:aos_durations[2])}
            className={`border border-accent-2 bg-accent-3 bg-opacity-30 backdrop-blur-md ${styles}`}>
            {
                imgPath && 
                <div className="border border-accent-2 bg-accent-2 bg-opacity-5 flex justify-center py-6 mb-10 w-[86%] ml-[50%] -translate-x-1/2 rounded">
                    <img src={imgPath} alt={imgAlt} className=" h-28"/>
                </div>
            }
            <h2 className="font-bold text-lg md:text-3xl mb-5">{heading}</h2>
            <p className="text-gray-500 md:text-lg" dangerouslySetInnerHTML={{__html: text}}></p>
        </div>
    );
}

export default HomeAboutSection;