import React, { useRef, useEffect, useContext, useState } from 'react';
import Masonry from 'react-masonry-css';
import DataContext from '../context/DataContext';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { useInView } from 'react-intersection-observer';
import { getBlurhash } from '../components/Blurhash';
import { Blurhash } from 'react-blurhash';

const ImageGallery = () => {

    let { offsetY, setCurrentPage, imageLoaded, handleImageLoad } = useContext(DataContext)

    useEffect(()=>{
        setCurrentPage('gallery')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    
    
    const images = [
        "/gallery/Frame 1533.png",
        "/gallery/Frame 1524.png",
        "/gallery/Frame 1534.png",
        
        "/gallery/Frame 1529.png",
        "/gallery/Frame 1541.png",
        "/gallery/Frame 1532.png",
        "/gallery/Frame 1535.png",
        "/gallery/new 1/Frame 1532.png",
        "/gallery/new 1/Frame 1539.png",
        "/gallery/new 1/Frame 1540.png",
        "/gallery/new 1/Frame 1541.png",
        "/gallery/new 1/Frame 1543.png",

    ];


    const images2 = [
        "/gallery/Frame 1537.png",
        "/gallery/new 2/Frame 1544.png",
        "/gallery/new 2/Frame 1545.png",
        "/gallery/new 2/Frame 1547.png",
        "/gallery/Frame 1538.png",
        "/gallery/new 2/Frame 1553.png",
        "/gallery/Frame 1539.png",
        "/gallery/new 2/Frame 1552.png",
        "/gallery/Frame 1540.png",
        "/gallery/new 2/Frame 1539.png",
        "/gallery/new 2/Frame 1546-1.png",
        "/gallery/new 2/Frame 1546.png",
        "/gallery/new 2/Frame 1548.png",
    ]
    const illutration = "/gallery/Group 1518.png"

    const containerRef = useRef(null);
    const { ref, inView } = useInView()


    useEffect(() => {
        const handleScroll = () => {
        
            const columns = document.getElementsByClassName('my-masonry-grid_column');
        
            Array.from(columns).forEach((column, index) => {
                column.style.transform = offsetY >= 80 ? index===0?`translateY(-${offsetY*0.1}px`:index===1?`translateY(-${offsetY*0.3}px`:`translateY(-${offsetY*0.5}px` : 'translateY(0)'
            });
            const columns1 = document.getElementsByClassName('my-masonry-grid_column1');
        
            Array.from(columns1).forEach((column, index) => {
                column.style.transform = offsetY >= 80 ? index===0?`translateY(-${offsetY*0.05}px`:index===1?`translateY(${offsetY*0}px`:`translateY(${offsetY*0.05}px` : 'translateY(0)'
            });
        };
        

        window.addEventListener('scroll', handleScroll);
    
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [offsetY]);
    const [activeImage, setActiveImage] = useState(null)
    const [activeIndex, setActiveIndex] = useState(null)

    useEffect(()=>{
        if (activeImage===null) {
            return;
        } else {
            const handleKeyDown = (event) => {
                if (event.key === 'ArrowLeft') {
                    setActiveImage(images[activeIndex-1])
                    setActiveIndex(activeIndex-1)
                } else if (event.key === 'ArrowRight') {
                    setActiveImage(images[activeIndex+1])
                    setActiveIndex(activeIndex+1)
                }
            };
        
            document.addEventListener('keydown', handleKeyDown);
        
            return () => {
                document.removeEventListener('keydown', handleKeyDown);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[activeImage]);


    const breakpointColumnsObj = {
        default: 3,
        1100: 3,
        700: 2,
        400: 1
    };

    const [visible, setVisible] = useState(false);
    if (inView && !visible) {
        setVisible(true);
    } else if (!inView && visible) {
        setVisible(false);
    }

    const [isScrolling, setIsScrolling] = useState(false);

    useEffect(() => {
        let scrollTimer;
    
        const handleScroll = () => {
            setIsScrolling(true);

            clearTimeout(scrollTimer);

            scrollTimer = setTimeout(() => {
            setIsScrolling(false);
            }, 150);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            clearTimeout(scrollTimer);
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);



    const animClasses = isScrolling ? [] : ['animated-bg', 'animated-bg-1', 'animated-bg-2', 'animated-bg-3', 'animated-bg-4'];
    const getRandomClass = () => {
        const randomIndex = Math.floor(Math.random() * animClasses.length);
        return animClasses[randomIndex];
    };



    const randomHeights = useRef({});

    const getRandomHeight = (image) => {
        if (!randomHeights.current[image]) {
            let randomNumber = Math.random()
            randomHeights.current[image] = randomNumber < 0.3 ? '180px' :  randomNumber < 0.5 ? '240px' : randomNumber > 0.7 ? '310px' : '200px';
        }
        return randomHeights.current[image];
    };
    

    return (
        <div className='overflow-x-hidden overflow-y-hidden'>
            <div>
                {
                    activeImage &&
                    <img src={activeImage} alt=""
                        style={{
                            position:"fixed",
                            top:'50%',
                            left:'50%',
                            transform: "translate(-50%,-50%)",
                            zIndex:"100"
                        }}
                        onLoad={()=>{}}
                    />
                }
            </div>
            <KeyboardArrowRight 
                className={`fixed top-[50%] -translate-y-1/2 scale-[3] hover:scale-[3.5] p-1 bg-white bg-opacity-20 hover:bg-opacity-30 z-[101] cursor-pointer rounded-3xl`}
                style={{
                    right: activeImage ? '28px' : '-100px'
                }}
                onClick={()=>{
                    setActiveImage(images[activeIndex+1])
                    setActiveIndex(activeIndex+1)
                }}
            />
            <KeyboardArrowLeft 
                className={`fixed top-[50%] -translate-y-1/2 scale-[3] hover:scale-[3.5] p-1 bg-white bg-opacity-20 hover:bg-opacity-30 z-[101] cursor-pointer rounded-3xl`}
                style={{
                    left: activeImage ? '24px' : '-100px'
                }}
                onClick={()=>{
                    setActiveImage(images[activeIndex-1])
                    setActiveIndex(activeIndex-1)
                }}
            />
            <div className='fixed w-full h-full bg-black bg-opacity-80 z-50 -bottom-[100%] cursor-pointer'
                title='Click to close'
                style={{
                    bottom:activeImage?'0':'100%'
                }}
                onClick={()=>{
                    setActiveImage(null)
                }}
            >
            </div>
            <section className="text-center w-full my-3">
                <h2 className=" text-xs font-medium text-gray-300">MASPEQA STUDIO</h2>
                <h1 className="text-4xl font-bold">PROJECTS GALLERY</h1>
            </section>
            <div className="container mx-auto mt-10 -z-50" ref={containerRef}>
                <Masonry
                    breakpointCols={breakpointColumnsObj}
                    className="my-masonry-grid"
                    columnClassName="my-masonry-grid_column"
                >
                    {
                        images.map((image, index) => (
                            <div key={index} className="relative rounded-md shadow-md scroll-1">
                                {
                                !imageLoaded[image] && (
                                    <Blurhash
                                        hash={getBlurhash(image)}
                                        width={"100%"}
                                        height={getRandomHeight(image)}
                                        punch={1}
                                        className={`blurhash-pulse rounded-3xl h-[850px]`}
                                    />
                                )}
                                <img
                                    src={image}
                                    alt={`Maspeqa Gallery Section 1 - ${index + 1}`}
                                    loading='lazy'
                                    className={`h-auto cursor-pointer hover:scale-105 ${getRandomClass()}`}
                                    onLoad={() => handleImageLoad(image)}
                                    style={{
                                        opacity: imageLoaded[image] ? 1 : 0,
                                        position: imageLoaded[image] ? 'static' : 'absolute',
                                        zIndex: imageLoaded[image] ? 'auto' : -1,
                                    }}
                                    onClick={()=>{
                                        setActiveImage(image)
                                        setActiveIndex(index)
                                    }}
                                />
                            </div>
                        ))
                    }
                </Masonry>
            </div>
            <div className={`mt-20 mx-auto`} style={{transform:``}} ref={ref}>
                <Masonry
                    breakpointCols={breakpointColumnsObj}
                    className="my-masonry-grid"
                    columnClassName="my-masonry-grid_column my-masonry-grid_column1"
                >
                    {
                        images2.map((image, index) => (
                            <div key={index} className="relative rounded-md shadow-md">
                                {
                                !imageLoaded[image] && (
                                    <Blurhash
                                        hash={getBlurhash(image)}
                                        width={"100%"}
                                        height={getRandomHeight(image)}
                                        punch={1}
                                        className={`blurhash-pulse rounded-3xl h-[850px]`}
                                    />
                                )}
                                <img
                                    src={image}
                                    alt={`Maspeqa Gallery Section 2_${index+1}`}
                                    loading='lazy'
                                    className={`h-auto hover:scale-105 ${isScrolling?'':Math.random() > 0.2 ? getRandomClass() : ''}`}
                                    onLoad={() => handleImageLoad(image)}
                                />
                            </div>
                        ))
                    }
                </Masonry>
            </div>
            <img src={illutration} alt="" className={`w-full my-3`} />
        </div>
    );
};

export default ImageGallery;
