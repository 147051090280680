const HomeServiceSection = ({ styles, children, data_aos }) => {


    
    return ( 
        <div data-aos='fade-right' data-aos-duration='5000' className={`${styles} overflow-hidden w-full sm:w-5/12 md:w-5/12 lg:w-auto my-3 lg:my-0 max-h-14 lg:max-h-max rounded-md lg:grid-item relative py-28 px-28 text-left font-bold text-lg`}>
            {children}
        </div>
    );
}

export default HomeServiceSection;