import { useEffect } from 'react';

const CalendlyWidget = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://assets.calendly.com/assets/external/widget.js';
        script.async = true;
        document.body.appendChild(script);

        script.onload = () => {
            window.Calendly.initBadgeWidget({
                url: 'https://calendly.com/maspeqa/30min',
                text: 'Schedule a meeting',
                color: 'rgb(0, 149, 255)',
                textColor: '#ffffff',
                branding: undefined
            });
        };

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return null;
};

export default CalendlyWidget;
