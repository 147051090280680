import blurhashData from '../media/blurhash/blurhash.json';



export const getBlurhash = (imagePath) => {
    const relativePath = imagePath.startsWith('/') ? imagePath.substring(1) : imagePath;

    const cleanedPath = relativePath.replace('media/', '');

    // Find and return the corresponding Blurhash or null if not found
    const hash = blurhashData[cleanedPath];
    return hash && hash.length >= 6 ? hash : null;
};