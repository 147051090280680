import { Link } from "react-router-dom";

const ListElement = ({to, text, active, setOpen}) => {

    const scrollToTop = () =>{
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        setOpen && setOpen(false)
    }


    return ( 
        <li className="inline-block relative group" onClick={scrollToTop}>
            <Link to={to} className={`inline-block p-2 my-2 md:mt-0 group-hover:text-accent ${active?'md:text-accent-2':'md:text-white'}`}>
                {text}
            </Link>
            {
                active && <span className=" group-hover:bg-accent block absolute bottom-3 left-1/2 -translate-x-1/2 w-1 h-1 bg-accent-2 rounded-full"></span>
            }
        </li>
    );
}

export default ListElement;