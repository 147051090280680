import './toggleButton.css'
import { useEffect } from "react";

const ToggleButton = ({scrollFromNav, open, setOpen}) => {


    useEffect(()=>{
        
        const animateShowNav = () =>{
            document.getElementById('top-menu').classList.add('top-menu-click')
            document.getElementById('top-menu').classList.remove('top-menu-click-reverse')

            document.getElementById('middle-menu').classList.add('middle-menu-click')

            document.getElementById('bottom-menu').classList.add('bottom-menu-click')
            document.getElementById('bottom-menu').classList.remove('bottom-menu-click-reverse')

            setTimeout(()=>{
                document.getElementById('top-menu').classList.add('top-menu-clicked')
                document.getElementById('middle-menu').classList.add('middle-menu-clicked')
                document.getElementById('bottom-menu').classList.add('bottom-menu-clicked')
            },500)
        }
        
        const animateHideNav = () =>{
            document.getElementById('top-menu').classList.remove('top-menu-click')
            document.getElementById('top-menu').classList.add('top-menu-click-reverse')

            document.getElementById('middle-menu').classList.remove('middle-menu-click')

            document.getElementById('bottom-menu').classList.remove('bottom-menu-click')
            document.getElementById('bottom-menu').classList.add('bottom-menu-click-reverse')

            
            setTimeout(()=>{
                document.getElementById('top-menu').classList.remove('top-menu-clicked')
                document.getElementById('middle-menu').classList.remove('middle-menu-clicked')
                document.getElementById('bottom-menu').classList.remove('bottom-menu-clicked')
            },500)
        }
        
        open ? animateShowNav() : animateHideNav()

        // open ?  : middleMenu.classList.remove('middle-menu-click')
        // open ?  : bottomMenu.classList.remove('bottom-menu-click')
    },[open])
    
    return ( 
        <div
            onClick={() => setOpen((prev) => !prev)}
            className={`flex relative flex-col justify-between items-end hover:scale-100 z-[500]  w-[40px] h-[23px] rounded-full ${scrollFromNav?'right-[23px] top-[10px]':'right-[15px] top-[8px]'} border-none cursor-pointer md:hidden`}
        >
            
            <div className="top-menu" id="top-menu"></div>
            <div className="middle-menu" id="middle-menu"></div>
            <div className="bottom-menu" id="bottom-menu"></div>
        </div>
    );
}

export default ToggleButton;