import Footer from "../footer/Footer";
import Nav from "../nav/Nav";
import { useContext } from "react";
import DataContext from "../../context/DataContext";

const Layout = ({children}) => {

    let { navHeight } = useContext(DataContext)

    return (
        <div>
            <div className="overflow-x-hidden 2xl:bg-black 2xl:w-[1540px] 2xl:ml-[50%] 2xl:-translate-x-1/2 2xl:rounded-lg 2xl:my-2 text-white">
                <Nav />
                <div
                    className="md:px-[2.5%] lg:px-[5.5%] 2xl:w-[1540px] 2xl:ml-[50%] 2xl:-translate-x-1/2 2xl:border-x 2xl:border-x-yellow-500"
                    style={{ marginTop: `${navHeight}px` }}
                >
                    <div className="px-2">
                        {children}
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
}

export default Layout;