import { createContext, useEffect, useState } from 'react';


const DataContext = createContext();

export default DataContext

export function DataProvider({ children }) {

    const [navHeight, setNavHeight] = useState()
    const [currentPage, setCurrentPage] = useState('home')

    const [availableHeight, setAvailableHeight] = useState(window.innerHeight)

    const phoneNumber = "+250791376036"
    
    // GETTING THE HEIGHT OF NAVBAR
    useEffect(() => {
        const updateAvailableHeight = () => {
            setAvailableHeight(window.innerHeight-navHeight);
        };
        updateAvailableHeight();

        const handleResize = () => {
            updateAvailableHeight();
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [navHeight]);


    // GETTING SCROLL HEIGHT
    const [offsetY, setOffsetY] = useState(0)
    const handleScroll = () => setOffsetY(window.scrollY)
        useEffect(()=>{
            window.addEventListener("scroll", handleScroll)

            return () => window.removeEventListener("scroll", handleScroll)
        })
    
    const [isMdScreen, setIsMdScreen] = useState(false);
    useEffect(() => {
        const checkScreenSize = () => {
            setIsMdScreen(window.innerWidth >= 768);
        };
        checkScreenSize();
        window.addEventListener('resize', checkScreenSize);

        return () => {
            window.removeEventListener('resize', checkScreenSize);
        };
    }, []);

        // Check if images has been loaded to remove blurry placeholders
        const [imageLoaded, setImageLoaded] = useState({});
        const handleImageLoad = (src) => {
            setImageLoaded((prev) => ({ ...prev, [src]: true }));
        };
        

    let contextData = {
        phoneNumber,
        navHeight,
        setNavHeight,
        currentPage,
        setCurrentPage,
        availableHeight,
        offsetY,
        isMdScreen,
        setIsMdScreen,
        imageLoaded,
        handleImageLoad
    }

    return (
        <DataContext.Provider value={contextData}>
            {children}
        </DataContext.Provider>
    );
}
    